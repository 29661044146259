import React, { useState } from 'react';
import { Form, Button, Input, Select, Checkbox, Row, Col } from 'antd';
import QuillWrapper from '../quillWrapper';
import FileUpload from '../fileUpload';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const AddUpdateNewsForm = ({ selected, onCreate, onUpdate, onClose }) => {
	const [ form ] = Form.useForm();
	const [ image, setImage ] = useState(selected ? selected.image : null);
	const [ message, setMessage ] = useState(selected ? selected.text : {ops:[]});
	const [ type, setType ] = useState('news');

	const onTypeChange = (value) => {
		form.setFieldsValue({ type: value });
		setType(value);
	};

	const onSubmit = (values) => {
		if (selected) {
			onUpdate({
				...selected,
				...values,
				text: message,
				image
			});
		} else {
			onCreate({
				...values,
				text: message,
				image
			});
		}
		onClose();
	};

	const onCompleteUpload = (url) => {
		setImage(url);
	};

	return (
		<Form
			form={form}
			{...formItemLayout}
			initialValues={{
				title: selected ? selected.title : '',
				intro: selected ? selected.intro : '',
				//text: selected ? selected.text : '',
				tag: selected ? selected.tag : '',
				type: selected ? selected.type : 'news',
				focus: selected ? selected.focus : false
			}}
			onFinish={onSubmit}
		>
			<Form.Item label="Titel" name="title" rules={[ { required: true, message: 'Titel is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item label="Korte intro" name="intro" rules={[ { required: true, message: 'Intro is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item label="Bericht" name="text">
        <QuillWrapper text={selected ? selected.text : ''} submissionHandler={setMessage}/>
	    </Form.Item>

			<Form.Item label="Tag" name="tag">
				<Input />
			</Form.Item>

			<Form.Item label="Uitgelicht op homepage" name="focus" valuePropName="checked" {...formItemLayout}>
				<Checkbox name="focus" />
			</Form.Item>

			<Form.Item label="Type" name="type" {...formItemLayout}>
				<Select name="type" onChange={onTypeChange} style={{ width: 120 }}>
					<Select.Option value="news">Nieuws</Select.Option>
					<Select.Option value="event">Evenement</Select.Option>
					<Select.Option value="program">Programma</Select.Option>
					<Select.Option value="suggestions">Luistertips</Select.Option>
					<Select.Option value="adminLinks">Links pagina admin</Select.Option>
					<Select.Option value="adminTestServer">Testserver pagina admin</Select.Option>
					<Select.Option value="adminUitleg">Uitleg pagina admin</Select.Option>
				</Select>
			</Form.Item>

			{ (type === 'news' || type === 'event') &&
				<Row style={{ marginBottom: 16 }}>
					<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
						<FileUpload
							multiple={false}
							images={image ? [image] : []}
							onRemove={() => setImage(null)}
							onComplete={onCompleteUpload}
						/>
					</Col>
				</Row>
			}

			<Form.Item {...tailLayout}>
				<Button htmlType="submit" type="primary">
					Opslaan
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddUpdateNewsForm;
