import React from "react"
import { Button, Space, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const NewsTable = ({ newsItems, onSelect, onRemove, pagination }) => {
	return (
		<Table
			columns={[
				{
					title: 'Titel',
					dataIndex: 'title',
					key: 'title'
				},
				{
					title: 'Datum',
					key: 'dateTime',
					dataIndex: 'dateTime'
				},
				{
					title: 'Type',
					key: 'type',
					dataIndex: 'type'
				},
				{
					title: 'Uitgelicht',
					key: 'focus',
					dataIndex: 'focus',
					render: (focus) => (
						<Tag color={focus ? 'green' : 'red'}>
							{ focus ? 'Uitgelicht' : 'Niet uitgelicht'}
						</Tag>
					)
				},
				{
					title: 'Action',
					key: 'action',
					className: 'column-align-right',
					render: (text, record) => {
						return (
							<Space>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<EditOutlined />}
									onClick={() => onSelect(record)}
								/>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<DeleteOutlined />}
									onClick={() => onRemove(record)}
									danger
								/>
							</Space>
						);
					}
				}
			]}
			rowKey='_id'
			pagination={pagination}
			dataSource={newsItems}
		/>
	);
}
export default NewsTable;
