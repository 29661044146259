import React, { useState, useEffect, useContext } from 'react';

import AdminLayout from '../../layouts/admin';
import SEO from '../../components/seo';

import NewsTable from '../../components/admin/news/newsTable';
import AddUpdateNewsForm from '../../components/admin/news/addUpdateNewsForm';

import { NewsContext } from '../../contexts/newsContext';

import Loader from '../../components/loader';

import { Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const AdminMessagesPage = ({ location }) => {
  const {
		currentPage, total, pageSize,
		newsItems, newsItemsLoading, getNewsItems,
		createNewsItem: createItem, newsItemCreating, newsItemCreatingError,
		updateNewsItem: updateItem, newsItemUpdating, newsItemUpdatingError,
		removeNewsItem: removeItem, newsItemRemoving, newsItemRemovingError
	} = useContext(NewsContext);

  const [newsItem, setNewsItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		getNewsItems(true);
	}, []);
  
  const selectNewsItem = (newsItemToSelect) => {
		setShowModal(newsItemToSelect != null);
		setNewsItem(newsItemToSelect);
		setShowModal(newsItemToSelect != null);
	};

	const createNewsItem = async (newsItemToCreate) => {
		const result = await await createItem(newsItemToCreate);
		if (!result.errors) {
			selectNewsItem(null);
		} else {
			message.error(result.errors.map(e => e.message).join('. '));
		}
	};

	const updateNewsItem = async (newsItemToUpdate) => {
		const result = await await updateItem(newsItemToUpdate);
		if (!result.errors) {
			selectNewsItem(null);
		} else {
			message.error(result.errors.map(e => e.message).join('. '));
		}
  };
  
  const removeNewsItem = async (newsItemToDelete) => {
    Modal.confirm({
      title: 'Weet je het zeker',
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je '${newsItemToDelete.title}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      onOk: async () => {
				const removed = await removeItem(newsItemToDelete);
				if (removed) {
					selectNewsItem(null);
				} else {
					message.error('Er ging iets mis bij het verwijderen.');
				}
      }
    });
  }

  return (
    <AdminLayout path={location.pathname} breadcrumbs={[{ link: '/admin/messages', title: 'Berichten'}]}>
      <SEO title="Admin berichten" />

			<h1>
				Berichten
				<Button type="default" onClick={() => setShowModal(true)} disabled={newsItem}>
					Bericht toevoegen
				</Button>
			</h1>

			{newsItemsLoading ? (
				<Loader />
			) : (
				<NewsTable
					pagination={{
						simple: true,
						pageSize,
						total,
						onChange: (page) => getNewsItems(true, page),
						current: currentPage
					}}
					newsItems={newsItems}
					onSelect={selectNewsItem}
					onRemove={removeNewsItem}
				/>
			)}

			{showModal && (
				<Modal
					title="Bericht toevoegen/wijzigen"
					visible={true}
					onCancel={() => selectNewsItem(null)}
          footer={null}
          width={'90vw'}
				>
					<AddUpdateNewsForm
						selected={newsItem}
						onCreate={createNewsItem}
            onUpdate={updateNewsItem}
						onClose={() => setShowModal(false)}
					/>
				</Modal>
			)}
		</AdminLayout>
	);
}

export default AdminMessagesPage
